import { initializeApp } from '@firebase/app';
import { getDatabase, onDisconnect, ref, set } from '@firebase/database';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from '@firebase/firestore';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { firebaseConfig } from '../config/firebase.config';
import { getUserPhoto } from '../utils/utils';
import api from './api';
import { getStudentsSchool } from './StudentService';

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
let dir_employees = 'https://www.labfut.com.br/upload/schools/students/';
let dir_student = 'https://www.labfut.com.br/upload/schools/students/';

export const getContactList = async (name = '', school_id = null, offset = 0, limit = 20, companie_id = '') => {
  const res = await getChatUsers({
    name,
    school_id,
    offset,
    limit,
    is_school: true,
    companie_id,
  });

  const students = res.data || [];
  if (students.length > 0) {
    dir_employees = res.dir_employees;
    dir_student = res.dir_student;
  }

  let contactsUsers = [];
  if (students.length > 0) {
    contactsUsers = students.map((student) => {
      const dir_usuFotoPrincipal = student.role === 'Atleta' ? dir_student : dir_employees;
      return {
        avatar: getUserPhoto(dir_usuFotoPrincipal + '/', student.photo || '13077_100410202305066456504a48361.jpg'),
        email: student.email,
        user_id: student.idUser,
        name: student.name,
        status: 'offline',
        role: student.role,
      };
    });
  }

  return { contactsUsers, total: res?.countRows || 0 };
};

export const getChatUsers = async ({
  name = '',
  school_id = '',
  offset = 0,
  limit = 20,
  is_school = companie_id === '' || companie_id === null ? true : false,
  companie_id = '',
}) => {
  try {
    const response = await api.post('get-users-chat', {
      is_school: is_school,
      school_id: school_id,
      companie_id: companie_id,
      name: name,
      pagination: {
        offset,
        limit,
      },
    });

    return response.data || [];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const findStudendById = async (student_id, school_id = null) => {
  try {
    const data = await getStudentsSchool({
      schoolId: school_id,
      companieId: '',
      paginationOffset: 0,
      paginationLimit: 9999999, // TODO: Rever isso no futuro
      filters: {
        filterStatus: 'A',
        filterCodAluno: student_id.toString(),
      },
    });

    return { ...data.students[0], dir_usuFotoPrincipal: data.dir_usuFotoPrincipal };
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const findStudendByName = async (name, school_id = null) => {
  try {
    const data = await getStudentsSchool({
      schoolId: school_id,
      companieId: '',
      paginationOffset: 0,
      paginationLimit: 9999999, // TODO: Rever isso no futuro
      filters: {
        filterStatus: 'A',
        filterName: name,
      },
    });

    return { ...data.students[0], dir_usuFotoPrincipal: data.dir_usuFotoPrincipal };
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const findUserById = async (firestore, user_id) => {
  try {
    const userRef = collection(firestore, 'users');
    const q = query(userRef, where('user_id', '==', user_id));
    const result = await getDocs(q);

    if (result.empty) {
      return { empty: true };
    }

    const user = result.docs[0].data();
    return { ...user, id: result.docs[0].id };
  } catch (error) {
    console.error(error);
    return { empty: true };
  }
};

export const fetchChats = async (firestore, sender_id, receiver_id) => {
  const chatsRef = collection(firestore, 'chats');
  // Consulta para buscar os chats
  let q = query(chatsRef, where('sender_id', '==', sender_id), where('receiver_id', '==', receiver_id));
  let querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    q = query(chatsRef, where('sender_id', '==', receiver_id), where('receiver_id', '==', sender_id));

    querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      return [];
    }
  }

  const chats = [];
  querySnapshot.forEach((doc) => {
    chats.push({ ...doc.data(), id: doc.id });
  });
  return chats;
};

export const findChatDocId = async (firestore, doc_id) => {
  try {
    const docRef = doc(firestore, 'chats', doc_id);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap : {};
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const createNewMessage = async (firestore, sender_id, doc_id, message) => {
  try {
    const novaMensagem = {
      content: message,
      created_at: new Date().getTime(),
      seen_by: [sender_id],
      sender_id: sender_id,
    };

    const docRef = doc(firestore, 'chats', doc_id);

    const docSnap = await getDoc(docRef);

    const docData = docSnap.data();

    const messages = docData?.messages || [];
    messages.push(novaMensagem);

    await updateDoc(docRef, {
      messages: messages,
    });
    return true;
  } catch (error) {
    console.error('Erro ao adicionar a nova mensagem:', error);
    return false;
  }
};

export const updateUserStatus = async (firestore, user_id, status) => {
  try {
    const userDoc = collection(firestore, 'users');

    const q = query(userDoc, where('user_id', '==', user_id));

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      throw new Error('Usuário não encontrado');
    }

    const user = querySnapshot.docs[0];

    await updateDoc(doc(firestore, 'users', user.id), {
      status: status,
    });
    return true;
  } catch (error) {
    console.error('Erro ao atualizar o status do usuário:', error);
    return false;
  }
};

export const createUserWithEmailAndPasswordFirebase = async (email, password) => {
  try {
    const auth = getAuth();
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const signInWithEmailAndPasswordFirebase = async (email, password, user_data) => {
  try {
    const auth = getAuth();
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    // Signed in
    const user = userCredential.user;
    handleConnectivityStatus();
    return user;
  } catch (error) {
    const { code } = error;
    if (code === 'auth/user-not-found') {
      if ((await createUserWithEmailAndPasswordFirebase(email, password)) !== null) {
        const user = await signInWithEmailAndPasswordFirebase(email, password);
        user_data.uid = user.uid;
        await createOrUpdateUserInFirestore(user_data);
        return user;
      }
      console.error('Erro ao criar o usuário');
      return null;
    }

    return null;
  }
};

export const createOrUpdateUserInFirestore = async (user_data) => {
  try {
    const userExists = await findUserById(firestore, user_data.user_id);

    if (userExists?.id) {
      deleteDoc(doc(firestore, 'users', userExists.id));
    }

    return await createUserInFirestore(user_data);
  } catch (error) {
    console.error('Erro ao criar o usuário no Firestore:', error);
    return null;
  }
};

export const createUserInFirestore = async (user_data) => {
  try {
    await setDoc(doc(firestore, 'users', user_data.uid), user_data);
    return user_data;
  } catch (error) {
    console.error('Erro ao criar o usuário no Firestore:', error);
    return null;
  }
};

export const handleConnectivityStatus = async (user) => {
  const database = getDatabase(app);
  if (!user) {
    return;
  }
  const userStatusDatabaseRef = ref(database, '/users/' + user.uid);

  const isOfflineForDatabase = {
    status: 'offline',
    last_changed: new Date().getTime(),
  };

  const isOnlineForDatabase = {
    status: 'online',
    last_changed: new Date().getTime(),
  };

  onDisconnect(userStatusDatabaseRef)
    .set(isOfflineForDatabase)
    .then(() => {
      set(userStatusDatabaseRef, isOnlineForDatabase);
    });
};

export const changeTypingStatus = async (doc_id, user_id, status) => {
  try {
    const docRef = doc(firestore, 'chats', doc_id);

    const docSnap = await getDoc(docRef);

    const docData = docSnap.data();

    const typing = docData?.typing || [];

    if (status) {
      typing.push(user_id);
    }

    if (!status) {
      typing.splice(typing.indexOf(user_id), 1);
    }

    await updateDoc(docRef, {
      typing: typing,
    });
    return true;
  } catch (error) {
    console.error('Erro ao atualizar o status de digitação:', error);
    return false;
  }
};

export const countUnreadMessages = (chats, myId) => {
  const chatUpdated = [...chats].sort((a, b) => b?.lastMessage - a?.lastMessage);

  let chatsWithUnreadMessages = 0;

  chatUpdated.forEach((chat) => {
    let count = 0;
    chat.messages.forEach((message) => {
      if (!message.seen_by.includes(myId) && message.sender_id !== myId) {
        count++;
      }
    });
    chat.unreadMessages = count;
    if (chat.unreadMessages > 0) {
      chatsWithUnreadMessages++;
    }
    chat.lastMessage = chat.messages[chat.messages.length - 1];
  });

  return { chatUpdated, chatsWithUnreadMessages };
};
