import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Footer from '../../components/school/Footer';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import { AllMonths, handleOnChange, range } from '../../../src/utils/utils';
import { useTranslation } from 'react-i18next';
import SelectRounded from '../../components/inputs/SelectRounded';
import moment from 'moment';
import ButtonRounded from '../../components/buttons/ButtonRounded';
import { getClassFrequencyReports } from '../../services/ReportService';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import SweetAlert from 'react-bootstrap-sweetalert';
import { FaCalendarWeek, FaFileExport, FaRegCalendarAlt, FaRegCalendarPlus, FaSearch } from 'react-icons/fa';
import { getClasses } from '../../services/ClassService';
import { formatarData } from '../../utils/date';
import InputRounded from '../../components/inputs/InputRounded';

function chunkArray(array, chunkSize) {
  const result = Array.from({ length: Math.ceil(array.length / chunkSize) }, (value, index) => {
    const start = index * chunkSize;
    const end = start + chunkSize;
    return array.slice(start, end);
  });

  return result;
}

const GeneralFrequencyReports = () => {
  const { t } = useTranslation();
  const { MessageDangerMarginTop } = Message();
  const schoolId = JSON.parse(localStorage.getItem('school')).id;
  const tableRef = useRef(null);

  const [confirmReceiptType, setConfirmReceiptType] = useState(null);
  const [studentClasses, setStudentClasses] = useState([]);
  const [classFrequency, setClassFrequency] = useState([]);

  const [filters, setFilters] = useState({
    classId: '',
    hour: '',
    year: moment().year(),
    month: moment().format('MM'),
    athleteName: '',
  });
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [students, setStudents] = useState([]);
  const [student, setStudent] = useState(null);
  const [monthPage, setMonthPage] = useState(0);
  const [monthPaginatedData, setMonthPaginatedData] = useState({
    year: moment().year(),
    month: moment().format('MM'),
  });

  useEffect(() => {
    fetchStudentClasses();
  }, []);

  const selectedData = useMemo(() => {
    const selectedClass = studentClasses.find((studentClass) => studentClass.id == filters.classId);
    return {
      class: selectedClass?.name,
      hour: filters.hour,
      month: filters.month,
      year: filters.year,
      students: selectedClass?.students,
      responsible: selectedClass?.schools?.schResponsavel,
    };
  }, [studentClasses, filters]);
  const monthPaginated = useMemo(() => {
    if (!classFrequency.length) return [];
    const allClassFrequencyDates = Object.keys(classFrequency[0].date_frequencies)
      .sort()
      .map((date) => new Date(date + 'T00:00:00'));
    return chunkArray(allClassFrequencyDates, 5);
  }, [monthPaginatedData, classFrequency]);

  const classFrequencyActive = useMemo(
    () =>
      classFrequency
        .filter((student) => student.user_status === 'A')
        .map((student) => ({ ...student, presenceDays: student.dates?.split(',') || [] })),
    [classFrequency]
  );
  const classFrequencyInactive = useMemo(
    () =>
      classFrequency
        .filter((student) => student.user_status === 'I')
        .map((student) => ({ ...student, presenceDays: student.dates?.split(',') || [] })),
    [classFrequency]
  );
  const onChangeFilters = handleOnChange(setFilters);

  const classFrequencyLeftClass = useMemo(
    () =>
      classFrequency
        .filter((student) => student.user_deleted_at !== null)
        .map((student) => ({ ...student, presenceDays: student.dates?.split(',') || [] })),
    [classFrequency]
  );

  const fetchClassFrequencyReports = async (event = null) => {
    event && event.preventDefault();

    clearMessage();
    setIsLoading(true);
    const { success, messageKey, classFrequency } = await getClassFrequencyReports(schoolId, filters);

    setIsLoading(false);
    if (success) {
      setClassFrequency(classFrequency);

      setMonthPaginatedData({
        month: filters.month,
        year: filters.year,
      });
    } else {
      showErrorMessage(messageKey);
    }
  };

  const fetchStudentClasses = async () => {
    const { classes } = await getClasses(schoolId);
    setStudentClasses(classes);
  };

  const showConfirmExportType = (typeExport) => {
    setConfirmReceiptType(
      <SweetAlert
        info
        title='Exportar o relatório?'
        showCloseButton
        closeOnClickOutside
        onCancel={() => setConfirmReceiptType(null)}
        customButtons={
          <>
            <button className='btn btn-lg btn-info' onClick={exportToXLS}>
              CSV
            </button>

            <button className='btn btn-lg btn-danger ml-3' onClick={() => setConfirmReceiptType(null)}>
              Cancelar
            </button>
          </>
        }
      />
    );
  };

  const clearMessage = () => {
    setMessage(null);
  };

  const showErrorMessage = (message) => {
    setMessage(message);
  };

  const exportToXLS = async () => {
    const studentDetails = students.find((s) => s.id === student.id);

    const frequencies = studentDetails?.frequencies;
    const studentClass = studentClasses.find((studentClass) => studentClass.id == filters.classId);
    const justifiedAbsence = frequencies
      ? frequencies.filter((frequency) => frequency.justification_title !== null).length
      : 0;
    const absence = frequencies ? frequencies.filter((frequency) => frequency.justification_title == null).length : 0;
    const presenceDays = frequencies ? frequencies.filter((frequency) => frequency.confirmed === 'S').length : 0;

    const totalOfClasses = absence + justifiedAbsence + presenceDays;
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const filename = `${(studentClass || { name: t('ClassFrequencyReportsPage.Report.AllClasses') }).name} ${
      filters.year
    }-${filters.month}.xlsx`;

    const data = classFrequency.map((student) => ({
      [t('ClassFrequencyReportsPage.Report.Name')]: student.name,
      [t('ClassFrequencyReportsPage.Report.Level')]: student.skill_level ?? '-',
      [t('ClassFrequencyReportsPage.Report.AbsencePercent')]: student.total_absence_percent,
      [t('ClassFrequencyReportsPage.Report.PresencePercent')]: student.total_presence_percent,
      [t('ClassFrequencyReportsPage.Report.Absences')]: student.total_absence,
      [t('ClassFrequencyReportsPage.Report.Presences')]: student.total_presence,
      [t('ClassFrequencyReportsPage.Report.Justifications')]: student.total_justified,
    }));

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(blob, filename);
    setConfirmReceiptType(null);
  };

  const onChangeClass = useCallback((e) => {
    e.persist();
    return setFilters((f) => ({
      ...f,
      classId: e.target.value,
      hour: '',
    }));
  }, []);
  return (
    <>
      <main>
        <div className='container-fluid container_painel'>
          <div className='container'>
            <div className='row content_painel'>
              <main className='content_panel' style={{ minHeight: 'auto', alignItems: 'center' }}>
                <div className='card' style={{ justifyContent: 'center' }}>
                  <div className='card_header' style={{ alignItems: 'center', width: '100%' }}>
                    <h2 className='card_title'>{t('ClassFrequencyReportsPage.Filter.Title')}</h2>
                    <p className='card_subtitle'>{t('ClassFrequencyReportsPage.Filter.Subtitle')}</p>
                  </div>

                  <form onSubmit={fetchClassFrequencyReports} style={{ alignItems: 'center' }}>
                    <div className='row w-100'>
                      <div className='col-md-4 p-0 d-flex justify-content-center'>
                        {[
                          {
                            emptyOption: false,
                            label: t('ClassFrequencyReportsPage.Filter.ChooseTheClass'),
                            name: 'classId',
                            value: filters.classId,
                            options: [
                              { label: 'TODAS AS TURMAS', value: 0 },
                              ...studentClasses.map((studentClass) => ({
                                value: studentClass.id,
                                label: studentClass.name,
                              })),
                            ],
                          },
                        ].map((select, i) => (
                          <div key={'ClassFrequencyReportsFilter' + i} className='mx-2'>
                            <SelectRounded
                              iconPrefix={<FaSearch />}
                              required
                              label={select.label}
                              name={select.name}
                              value={select.value}
                              onChange={onChangeClass}
                            >
                              <option style={{ textAlign: 'left' }} value={''} disabled={!select.emptyOption}></option>
                              {select.options.map((option, j) => (
                                <option
                                  style={{ textAlign: 'left' }}
                                  key={'ClassFrequencyReportsFilterOptions' + i + j}
                                  value={option.value}
                                >
                                  {option.label}
                                </option>
                              ))}
                            </SelectRounded>
                          </div>
                        ))}
                      </div>

                      <div className='col-md-3 p-0 d-flex justify-content-center'>
                        {[
                          {
                            emptyOption: true,
                            label: t('ClassFrequencyReportsPage.Filter.ChooseTime'),
                            name: 'hour',
                            value: filters.hour,
                            options: +filters.classId
                              ? studentClasses
                                  .find((studentClass) => studentClass.id == filters.classId)
                                  .class_days.reduce((classDays, classDay) => {
                                    if (classDays.includes(classDay['hour'])) return classDays;
                                    classDays.push(classDay['hour']);
                                    return classDays;
                                  }, [])
                                  .map((hour) => ({
                                    value: hour,
                                    label: hour,
                                  }))
                              : [],
                          },
                        ].map((select, i) => (
                          <div key={'ClassFrequencyReportsFilter' + i} className='mx-2' style={{ width: '90%' }}>
                            <SelectRounded
                              iconPrefix={<FaRegCalendarAlt />}
                              label={select.label}
                              name={select.name}
                              value={select.value}
                              onChange={onChangeFilters}
                            >
                              <option value={''} disabled={!select.emptyOption}></option>
                              {select.options.map((option, j) => (
                                <option
                                  style={{ textAlign: 'left' }}
                                  key={'ClassFrequencyReportsFilterOptions' + i + j}
                                  value={option.value}
                                >
                                  {option.label}
                                </option>
                              ))}
                            </SelectRounded>
                          </div>
                        ))}
                      </div>

                      <div className='col-md-3 p-0 d-flex  justify-content-center'>
                        {[
                          {
                            emptyOption: false,
                            label: t('ClassFrequencyReportsPage.Filter.ChooseTheYear'),
                            name: 'year',
                            value: filters.year,
                            options: range(2015, moment().year() + 1).map((year) => ({ value: year, label: year })),
                          },
                        ].map((select, i) => (
                          <div
                            key={'ClassFrequencyReportsFilter' + i}
                            className='mx-2'
                            style={{ width: '90%', marginRight: '4px' }}
                          >
                            <SelectRounded
                              iconPrefix={<FaCalendarWeek />}
                              required
                              label={select.label}
                              name={select.name}
                              value={select.value}
                              onChange={onChangeFilters}
                            >
                              <option style={{ textAlign: 'left' }} value={''} disabled={!select.emptyOption}></option>
                              {select.options.map((option, j) => (
                                <option
                                  style={{ textAlign: 'left' }}
                                  key={'ClassFrequencyReportsFilterOptions' + i + j}
                                  value={option.value}
                                >
                                  {option.label}
                                </option>
                              ))}
                            </SelectRounded>
                          </div>
                        ))}
                      </div>

                      <div className='col-md-2 p-0 d-flex justify-content-center'>
                        {[
                          {
                            emptyOption: false,
                            label: t('ClassFrequencyReportsPage.Filter.ChooseTheMonth'),
                            name: 'month',
                            value: filters.month,
                            options: AllMonths.map((month) => ({ value: month.numberString, label: t(month.name) })),
                          },
                        ].map((select, i) => (
                          <div key={'ClassFrequencyReportsFilter' + i} className='mx-2' style={{ width: '90%' }}>
                            <SelectRounded
                              iconPrefix={<FaRegCalendarPlus />}
                              required
                              label={select.label}
                              name={select.name}
                              value={select.value}
                              onChange={onChangeFilters}
                            >
                              <option style={{ textAlign: 'left' }} value={''} disabled={!select.emptyOption}></option>
                              {select.options.map((option, j) => (
                                <option
                                  style={{ textAlign: 'left' }}
                                  key={'ClassFrequencyReportsFilterOptions' + i + j}
                                  value={option.value}
                                >
                                  {option.label}
                                </option>
                              ))}
                            </SelectRounded>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div style={{ width: '100%' }}>
                      <InputRounded
                        name='athleteName'
                        onChange={onChangeFilters}
                        placeholder={t('ClassFrequencyReportsPage.Filter.SearchAthlete')}
                        style={{ height: 47, textAlign: 'left' }}
                        label={t('ClassFrequencyReportsPage.Filter.Athlete')}
                        value={filters}
                        styleContainer={{ width: '100%' }}
                        iconPrefix={<FaSearch />}
                      />
                    </div>
                    <ButtonRounded style={{ width: '100%', height: '40px', borderRadius: '8px' }} type='submit'>
                      <FaSearch />
                      {t('Research')}
                    </ButtonRounded>
                  </form>
                </div>
              </main>

              <main className='content_panel' style={{ minHeight: 'auto' }}>
                <div className='card' ref={tableRef} style={{ maxWidth: '100vw' }}>
                  <div className='card_header' style={{ alignItems: 'center', width: '100%' }}>
                    <h2 className='card_title'>{t('ClassFrequencyReportsPage.Report.Title')}</h2>
                    <p className='card_subtitle'>{t('ClassFrequencyReportsPage.Report.Subtitle')}</p>
                    {!!message && (
                      <MessageDangerMarginTop title={t('Attention_exclamation') + ' '} description={t(message)} />
                    )}
                    <h4 className='mt-3 pdf-title' style={{ display: 'none' }}>
                      {selectedData.class} - {selectedData.hour}
                    </h4>
                    <div className='pdf-title' style={{ display: 'none' }}>
                      {selectedData.month}/{selectedData.year}
                    </div>
                  </div>
                  <div class='card-body'>
                    <div className='list_card'>
                      {isLoading ? (
                        <p className='loading-card'>
                          Sem dados! Faça uma busca.
                          <Loading type='1' />
                        </p>
                      ) : (
                        <>
                          <div className='w-100 d-flex mt-1 flex-column flex-sm-row'>
                            <div className='rectangle-container mx-auto text-center align-items-center'>
                              <div className='card_header mx-auto flex-1'>
                                <h2 style={{ color: '#27CD87' }}>
                                  {classFrequencyActive.length === 0 ? '--' : classFrequencyActive.length}
                                </h2>
                                <p className='card_subtitle'>Quantidade de alunos ativos</p>
                              </div>

                              <div className='card_header mx-auto flex-1'>
                                <h2 style={{ color: '#FF5656' }}>
                                  {classFrequencyInactive.length === 0 ? '--' : classFrequencyInactive.length}
                                </h2>
                                <p className='card_subtitle'>Quantidade de alunos inativos</p>
                              </div>

                              <div className='card_header mx-auto flex-1'>
                                <h2 style={{ color: '#F90' }}>
                                  {classFrequencyLeftClass.length === 0 ? '--' : classFrequencyLeftClass.length}
                                </h2>
                                <p className='card_subtitle'>Qtd. de alunos que sairam da turma</p>
                              </div>
                              <div></div>
                            </div>
                          </div>

                          {!selectedData.class ? (
                            <p></p>
                          ) : (
                            <p key={selectedData.class} className='card_subtitle d-flex d-md-block flex-column mt-2'>
                              {[
                                { key: t('Classes'), value: selectedData.class },
                                { key: t('Schedule'), value: selectedData.hour },
                                { key: 'Mes', value: selectedData.month },
                                { key: 'Ano', value: selectedData.year },
                                { key: t('Responsible'), value: selectedData.responsible },
                              ].map(({ key, value }, i) => (
                                <span key={`studentClass_list_infos_${selectedData.class}_${key}`}>
                                  {i !== 0 && <span className='mx-1 d-none d-md-inline'>|</span>}
                                  <b>{key}:</b> {value}
                                </span>
                              ))}
                            </p>
                          )}

                          {(selectedData.class || filters.classId === '0') && classFrequency.length ? (
                            <table
                              style={{
                                width: 'fit-content',
                                minWidth: '100%',
                                boxShadow: 'none',
                                borderRadius: 'initial',
                                overflowX: 'auto',
                                marginTop: '20px',
                              }}
                            >
                              <thead>
                                <tr style={{ width: 50, textAlign: 'center', justifyContent: 'center' }}>
                                  <th style={{ textAlign: 'left' }}>Nome</th>
                                  <th
                                    style={{ padding: `4px 8px`, cursor: monthPage === 0 ? 'not-allowed' : `pointer` }}
                                    onClick={() => (monthPage !== 0 ? setMonthPage((state) => state - 1) : null)}
                                  >{`<`}</th>
                                  {(monthPaginated[monthPage] || []).map((day) => (
                                    <th key={formatarData(day)} style={{ padding: `4px 8px` }}>
                                      {formatarData(day)}
                                    </th>
                                  ))}
                                  <th
                                    style={{
                                      padding: `4px 8px`,
                                      cursor: monthPage === monthPaginated.length - 1 ? 'not-allowed' : `pointer`,
                                    }}
                                    onClick={() =>
                                      monthPage !== monthPaginated.length - 1
                                        ? setMonthPage((state) => state + 1)
                                        : null
                                    }
                                  >{`>`}</th>
                                  <th>Nível</th>
                                  <th>% faltas</th>
                                  <th>% presença</th>
                                  <th>Faltas</th>
                                  <th>Presenças</th>
                                  <th>Justificativas</th>
                                </tr>
                              </thead>

                              <tbody style={{ marginTop: '5px' }}>
                                {classFrequency.map((student, i) => {
                                  const frequencies = student.date_frequencies;

                                  const justifiedAbsence = student.total_justified;
                                  const absence = student.total_absence;
                                  const presenceDays = student.total_presence;

                                  return (
                                    <tr key={i} style={{ width: 50, textAlign: 'center', justifyContent: 'center' }}>
                                      <td style={{ textAlign: 'left' }}>{student.name}</td>
                                      <td></td>
                                      {(monthPaginated[monthPage] || []).map((day) => (
                                        <td key={day} style={{ padding: 4 }}>
                                          <FrequencyText frequencies={frequencies} day={day} />
                                        </td>
                                      ))}
                                      <td></td>
                                      <td>{student.skill_level ?? '-'}</td>
                                      <td>{student.total_absence_percent}</td>
                                      <td>{student.total_presence_percent}</td>
                                      <td>{absence}</td>
                                      {/* <td>
                                      {daysMonth.length === 0
                                        ? 0
                                        : ((student.frequente / daysMonth.length) * 100).toFixed(0)}
                                    </td> */}
                                      <td>{presenceDays}</td>
                                      <td>{justifiedAbsence}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          ) : (
                            false
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className='card-footer bg-transparent w-100'>
                    <div
                      className='row w-100'
                      style={{
                        marginTop: '3.125rem',
                      }}
                    >
                      <div className='col d-flex justify-content-start align-itens-center p-0'>
                        <ButtonRounded onClick={showConfirmExportType} isDisabled={!classFrequency.length}>
                          <FaFileExport />
                          Exportar
                        </ButtonRounded>
                      </div>

                      {/*  <div
                   className='col d-flex justify-content-start align-itens-center p-0'
                    >
                      <span className='badge badge-success'>Presença</span>
                      <span className='badge badge-warning'>Justificativa</span>
                      <span className='badge badge-danger'>Falta</span>
                    </div> */}
                    </div>
                  </div>

                  {confirmReceiptType}
                </div>
              </main>
              <Footer />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

function FrequencyText({ frequencies, day }) {
  const [className, status] = useMemo(() => {
    if (!frequencies) return ['text', `-`];
    const frequency = frequencies[moment(day).format('YYYY-MM-DD')] ?? {};
    let textClass = '';
    switch (frequency.type) {
      case 'P':
        textClass = 'text-success';
        break;
      case 'J':
        textClass = 'text-warning';
        break;
      case 'F':
        textClass = 'text-danger';
        break;
      default:
        textClass = 'text';
        break;
    }
    return [textClass, frequency.type];
  }, [frequencies, day]);

  return <span className={className}>{status}</span>;
}

export default GeneralFrequencyReports;
